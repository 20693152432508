/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* App component style */
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

body {
  background-color: #ececec;
  font-family: 'Raleway', sans-serif;
  color: 'var(--text-color1)';
  /* height: 100%; */
  margin: 0;
  padding: '2em';
  font-size: 14px;
  background: rgba(255,204,0,1);
  background: linear-gradient(to bottom, rgba(255,204,0,1) 0%, rgba(255,229,133,1) 25%, rgba(255,255,255,1) 61%, rgba(255,255,255,1) 100%);
  background-repeat: no-repeat;
}
*{
  scroll-behavior: smooth;
}
html,
#root {
  /* height: 100%; */
}
#root {
  display: flex;
  flex-direction: column;
}
:root{
  --primary-color1: #DC3545;
  --primary-color2: #FDCB00;
  --primary-color3: #00B8C4;
  --secondary-color1: #FFCDD2;
  --secondary-color2: #A94442;
  --secondary-color3: #FFF7D4;
  --secondary-color4: #9F861E;
  --secondary-color5: #E0F6F4;
  --secondary-color6: #03848C;
  --text-color1: #212121;
  --text-color2: #757474;
  --text-color3: #E4E4E4;
}

.iti__flag {
  background-image: url('../../../node_modules/intl-tel-input/build/img/flags.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url('../../../node_modules/intl-tel-input/build/img/flags@2x.png');
  }
}

#category-list {
  color: 'var(--text-color1)';
  background-color: transparent;
  font-size: calc(10px + 3vmin);
  border: none;
  border-bottom: 5px solid 'var(--text-color1)';
  font-weight: 900;
  border-radius: 0;
}

.dropdown-item {
  font-weight: 900;
  line-height: 2em;
  font-size: calc(2vh);
}

.dropdown-menu {
  background-color: 'var(--primary-color2)';
}

.gallery-sub-nav {
  font-size: calc(10px + 3vh);
  font-weight: 900;
  display: flex;
  justify-content: space-between;
  background-color: 'var(--primary-color2)';
  padding: 20px;
  color: 'var(--text-color1)';
}

.gallery-sub-nav .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.sub-title {
  line-height: 2em;
}

.App {
  display: flex;
  flex-direction: column;
}

.App-logo {
  pointer-events: none;
  padding: 20px;
  height: calc(30px + 7vh);
  /* width: 100vw; */
}

.hamburger {
  pointer-events: none;
  padding: 20px;
}

.App-header {
  background-image: linear-gradient('var(--primary-color1)', 'var(--secondary-color2)');
  min-height: 7vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* font-size: calc(10px + 2vmin); */
  color: white;
  margin-bottom: 0px;
}

.App-link {
  color: #61dafb;
}

.profile_image {
  width: 3em;
  height: 3em;
  border: 2px solid black;
  border-radius: 50%;
}

/* VideoGallery component style */
.video-gallery {
  margin: 0px;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.vimeo-player {
  min-width: 100%;
  min-height: 100%;
}

.video-spread {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.video-item {
  float: left;
  width: 470px;
  height: 385px;
  margin: calc(10px + 3vh);
  background-color: #fc0;
  border: 10px solid #fc0;
  box-shadow: 10px 10px #cda500;
}

.video-item .row {
  margin-left: unset;
  margin-right: unset;
}

.video-item .col {
  padding-left: unset;
  padding-right: unset;
}

.video-item .container {
  padding-left: unset;
  padding-right: unset;
}

.video-title {
  white-space: nowrap;
  width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  font-size: calc(10px + 1vmin);
  font-weight: 800;
}

.video-url {
  cursor: pointer;
  text-align: right;
}

/* VideoPage component style */
.video-box {
  padding: 20px;
  flex: 1;
  background-color: black;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.video-controls {
  padding: 10px;
  background: #cda500;
  margin: 10px -20px -20px -20px;
  padding-left: 0px;
  padding-bottom: 0px;
}

.vimeo-container {
  flex: 1;
  align-self: center;
}

.video-info-box {
  padding: 40px;
  padding-top: 0px;
  flex: 1;
  background: #fc0;
}

.video-info-box .title {
  margin-top: 10px;
  font-weight: 900;
  font-size: calc(40px + 3vmin);
}

.video-info-box .created {
  padding-bottom: 10px;
}

.video-info-box .tags {
  background: 'var(--primary-color1)';
  font-weight: 900;
  font-size: 2vmin;
  color: 'var(--primary-color2)';
  margin-right: 10px;
  border-radius: 20px;
  padding: 5px 15px 5px 15px;
  display: inline-block;
  margin-bottom: 25px;
  margin-top: 15px;
  box-shadow: 4px 4px #cda500;
}

.video-info-box .description {
  font-size: calc(12px + 1vmin);
}

.video-page {
  margin: 0;
  padding: 0;
  min-width: 100%;
  display: flex;
  flex-direction: column;
}

.video-sub-nav {
  margin: 0;
  padding: 20px;
  background-color: 'var(--primary-color2)';
  min-width: 100%;
}

.video-page-view {
  display: flex;
}

.button-box {
  padding: 0px;
  margin: 10px;
}

#back-button {
  color: 'var(--text-color1)';
  background-color: transparent;
  font-size: calc(10px + 3vmin);
  border: none;
  font-weight: 900;
  border-radius: 0;
}

@media only screen and (max-width: 1224px) {
  .video-box iframe {
    width: 700px;
    height: 350px;
  }

  .video-page-view {
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .video-box iframe {
    width: 500px;
    height: 250px;
  }

  .video-spread {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .video-item {
    width: unset;
    height: unset;
    margin: calc(10px + 3vh);
    box-shadow: 10px 10px #cda500;
  }

  .video-page-view {
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  .video-box iframe {
    width: 350px;
    height: 200px;
  }

  .video-spread {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .video-item {
    width: unset;
    height: unset;
    margin: calc(10px + 3vh);
    box-shadow: 5px 5px #cda500;
  }

  .App-logo {
    pointer-events: none;
    padding: 20px;
    height: unset;
    width: 90vw;
  }

  .App-header {
    justify-content: center;
  }

  .video-page-view {
    flex-direction: column;
  }

  .video-info-box .title {
    font-size: calc(30px + 2vmin);
  }
}

.row {
  margin-right: unset;
  margin-left: unset;
  flex-direction: row;
}

/* Comments component style */

.comments-section {
  width: 100%;
  font-size: calc(12px + 1vmin);
}

.comments-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.comments-container h2 {
  margin-top: 10px;
  font-weight: 900;
}

.submit-section {
  padding: 30px;
  background-color: #dadada;
  border-radius: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 15px;
}

.post-as-row {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0px;
}

.username-label {
  padding: 0px;
  /* padding-right: 5px; */
  font-weight: 900;
}

.username-label p {
  margin: 0px;
  line-height: 50px;
}

.username-input {
  background-color: #fc0;
  box-shadow: 5px 5px #cda500;
}

.post-as-row input {
  width: 100%;
  height: 40px;
  padding: 12px 0px 8px 0px;
  border: none;
  background-color: transparent;
  color: 'var(--text-color1)';
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
  -webkit-appearance: none;
}

.publish-btn-chars-row {
  margin-top: 15px;
  margin-bottom: 15px;
}

.btn-box {
  padding: 0px;
  margin-bottom: 15px;
}

.btn-box .btn-publish {
  width: 100%;
}

.chars-box {
  padding: 0px;
  text-align: right;
  line-height: 10px;
}

.posted-by {
  font-weight: bold;
  padding: 0px;
}

.posted-by p {
  margin-bottom: 0px;
}

.posted-ago {
  padding: 0px;
  color: grey;
  font-weight: 500;
  font-size: 0.8rem;
  text-align: right;
  padding-top: 10px;
}

.posted-ago p {
  margin-bottom: 0px;
}

.search-form-input input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.slick-slider {
  padding-top: 2em;
}

.slick-center img {
  position: relative;
  height: 220px !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.detail-page-slider .slick-list {
  padding: 2em 40px !important;
  margin-left: 1em;
  margin-right: 1em;
}

.comment-box {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1em;
  background-color: white;
  max-width: 1000px;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0.8em;
}

.comment-meta {
  display: flex;
  flex-direction: row;
}

.comment-meta__a {
  color: 'var(--text-color1)';
  display: flex;
  align-items: center;
}

.comment-collapsed {
  display: flex;
  flex-direction: row;
  padding: 1em;
  background-color: white;
  max-width: 1000px;
  width: 100%;
  box-shadow: 0 1px 4px var(--text-color3);
  border: 1px solid var(--text-color3);
  margin: 0.8em;
}

.sub-comment-box {
  width: 90%;
  margin-left: 3em;
}

.comment-form,
.comment-text {
  width: 100%;
  height: 100%;
}

.comment-text {
  padding-top: 0.2em;
  font-size: 1.5rem;
  font-family: 'Raleway';
}

.comment-box.comment {
  -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 184, 196, 0.4);
  -moz-box-shadow: 0px 0px 3px 1px rgba(0, 184, 196, 0.4);
  box-shadow: 0px 0px 3px 1px rgba(0, 184, 196, 0.4);

  border: 1px solid rgba(0, 184, 196, 0.2);
}

.comment-text,
.comment-text:focus {
  border-color: transparent;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  resize: vertical;
}

.comment-box.comment .comment-text {
  height: 7em;
}

.comment-box.comment .sub-comment-text {
  height: 2em;
}

.comment-box .comment-form .comment-publish-button {
  margin-left: 0;
}

.no-button-click{
  background-color: #c8c8c8 !important;
  cursor: not-allowed !important;
}
.no-button-click > * {
  pointer-events: none;
  cursor: not-allowed !important;
}

.mention {
  text-decoration: none;
  color: 'var(--primary-color3)';
}

.mention:hover {
  color: 'var(--secondary-color6)';
}

/* Footer Styles */

.footer-logo {
  height: calc(10px + 7vh);
  pointer-events: none;
}

.btn {
  margin: 1em;
  padding: 1em;
  border-radius: 10px;
}

.btn-success {
  background-color: green;
}

.btn-primary {
  background-color: blue;
}

.btn-danger {
  background-color: red;
}

.display-none {
  display: none !important;
}

.MuiInputBase-root #phone{
  width: 80%;
}

.MuiTypography-body1,.MuiOutlinedInput-root{
  /* font-size: 0.85rem !important; */
}

/* .ql-editor.ql-blank::before {
  color:#B3B3B3 !important;
  font-style: normal !important;
}

.MuiOutlinedInput-input, .ql-editor, .ql-editor.ql-blank::before {
  font-size: 15px !important;
  font-family: 'Raleway', sans-serif !important;
}

.MuiOutlinedInput-input, .ql-editor {
  color: rgba(0, 0, 0, 0.87) !important;
}

input::placeholder {
  color:#B3B3B3 !important;
} */

